import React, { useRef, useEffect, useState, useCallback } from 'react';
import mapboxgl, { Point } from 'mapbox-gl';


function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
}

const MainMappingComponent = ({streetViewCoordinates_parent, streetView, map, hideStreetView}) => {
    const [streetViewCoordinates, setStreetViewCoordinates] = useState([]);
    const [showStreetView, setShowStreetView] = useState(false);
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
    const [locationHeading, setLocationHeading] = useState(165)
    const markerRef = useRef(null);
    const currentRotationRef = useRef(0);
    const markerElementRef = useRef(null);

    const googleMapRef = useRef(null);

    const loadGoogleMaps = (coordinates) => {
      if (!googleMapsLoaded) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBw0Yd60nL6ogVj2afid8VNG9sFbihbeSg`;
        script.id = 'google-maps-script';
        script.async = true;
        script.defer = true;
        script.onload = () => {
          googleMapRef.current = window.google;
          setGoogleMapsLoaded(true);
          checkStreetViewAvailability(coordinates);
        };
        document.body.appendChild(script);
      } else {

        checkStreetViewAvailability(coordinates);
      }
    };

    useEffect(() => {
        console.log('streetViewCoordinates_parent: ', streetViewCoordinates_parent)
        if(streetViewCoordinates_parent){
            loadGoogleMaps(streetViewCoordinates_parent);
        } else {
            // if(!streetView && googleMapsLoaded) unloadGoogleMaps();
        }
    }, [streetViewCoordinates_parent, streetView]);

    const updateMarkerRotation = (heading) => {
        if (markerRef.current) {
            console.log('heading: ', heading)

            markerRef.current.setRotation(heading);
        }
    };

    const debouncedUpdateMapboxPOV = useCallback(
        debounce((heading) => {
            // console.log('debounce: ', markerRef.current)
            if (markerRef.current) {
                currentRotationRef.current = heading;
                updateMarkerRotation(heading)
            }
        }, 150),
        []
    );

    const updateMapboxMarker = (lng, lat) => {
        if (map) {
            if (!markerRef.current) {
                const el = document.createElement('div');
                el.className = 'marker';
                el.style.backgroundImage = 'url(/map_images/NavigationUp.png)'; 
                el.style.width = '50px';
                el.style.height = '50px';
                el.style.backgroundSize = '100%';

                markerElementRef.current = el;

                markerRef.current = new mapboxgl.Marker({
                    element: el,
                    rotationAlignment: 'map',
                    pitchAlignment: 'map'
                })
                    .setLngLat([lng, lat])
                    .addTo(map);
            } else {
                markerRef.current.setLngLat([lng, lat]);
            }
            updateMarkerRotation(currentRotationRef.current);
        }
    };

    const checkStreetViewAvailability = (coordinates) => {
        const streetViewService = new googleMapRef.current.maps.StreetViewService();
        streetViewService.getPanorama({ location: { lng: coordinates[0], lat: coordinates[1] }, radius: 50 }, (data, status) => {
            if (status === googleMapRef.current.maps.StreetViewStatus.OK) {
                setShowStreetView(true);
                setStreetViewCoordinates(coordinates);
            } else {
                alert("No Street View available at this location");
                setShowStreetView(false);
                hideStreetView()
            }
        });
    };

    useEffect(() => {
        // console.log('google maps: ', googleMapsLoaded, showStreetView, streetViewCoordinates, )
        if(googleMapsLoaded && showStreetView && streetViewCoordinates.length === 2){
            const streetViewContainer = document.getElementById('street-view-container');
            console.log('streetViewContainer: ', streetViewContainer, googleMapRef)
            const mapboxContainer = document.getElementById('mapbox-main');
            const originalStyle = mapboxContainer.getAttribute('style');

            if (streetViewContainer && googleMapRef.current) {
                const panorama = new googleMapRef.current.maps.StreetViewPanorama(
                    streetViewContainer,
                    {
                        position: { lng: streetViewCoordinates[0], lat: streetViewCoordinates[1] },
                        pov: { heading: 165, pitch: 0 },
                        zoom: 1,
                        fullscreenControl: false
                    }
                );
                // Update Mapbox marker on panorama changes
                panorama.addListener('position_changed', () => {
                    const position = panorama.getPosition();
                    updateMapboxMarker(position.lng(), position.lat());
                });

                panorama.addListener('pov_changed', () => {
                    debouncedUpdateMapboxPOV(panorama.getPov().heading);
                });

                // Initial marker update
                updateMapboxMarker(streetViewCoordinates[0], streetViewCoordinates[1], 165);
                setTimeout(() => {
                    if (mapboxContainer.getAttribute('style') !== originalStyle) {
                        mapboxContainer.setAttribute('style', originalStyle);
                    }
                }, 100);
            }
        }
    }, [googleMapsLoaded, showStreetView, streetViewCoordinates]);


    const unloadGoogleMaps = () => {
        setShowStreetView(false);
        if (googleMapsLoaded) {
            const script = document.getElementById('google-maps-script');
            if (script) {
                script.remove();
                setGoogleMapsLoaded(false);
                googleMapRef.current = null;
                window.google = undefined;
                if (markerRef.current) {
                    markerRef.current.remove();
                    markerRef.current = null;
                }
                hideStreetView()
            }
        }
    };

    return (
        <div className='main-mapping-container'>
            <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flex: 1, position: 'relative' }} id="mapbox-main" data-tut='reactour__map' />
                    {showStreetView && googleMapsLoaded && (
                        <div style={{ height: '50%', position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1
                        }}>
                            <button 
                                onClick={unloadGoogleMaps}
                                className='map-streetview-exit'
                            >
                            X
                            </button>    
                            <div id="street-view-container" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainMappingComponent;